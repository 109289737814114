import axios from 'axios';
import BaseAPIClient from '@/services/baseClient';

export class ApiClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();

    this.baseURL = process.env.NODE_ENV === 'production' ? '/api' : process.env.VUE_APP_API_URI;
    this.instance.defaults.baseURL = this.baseURL;
  }

  async authenticate(user) {
    return this.instance.post('login', user);
  }

  async compareSingle(source, target) {
    return this.instance.post('check-single', {
      name: source,
      number: target,      
    });
  }

  async compareNames(source, target) {
    return this.instance.post('name-compare', {
      name1: source,
      name2: target,      
    });
  }

  async compareBatch(file) {
    const formData = new FormData();
    formData.append('batch', file);

    return this.instance.post('check-batch' , formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
  
  async getDailyReport(params) {
    return this.instance.get('/daily', { params });
  }

  async getQuerylogs(type) {
    return this.instance.get(`/querylogs/${type}`);
  }

  async getUsers() {
    return this.instance.get('/users');
  }

  async getThresholds() {
    return this.instance.get('/thresholds');
  }

  async setUser(user) {
    return this.instance.post('set-user', user);
  }

  async deleteUser(id) {
    return this.instance.delete(`delete-user/${id}`);
  }

  setThresholds(thresholds) {
    return this.instance.post('set-thresholds', thresholds);
  }

  logout = async () => {
    const userToken = localStorage.getItem('user-token');
    const username = localStorage.getItem('user-name');
    if (userToken) {
      const headers = {};
      headers['x-access-token'] = userToken;
      
      return await this.instance.post('/logout', { username }, { headers });
    }
  }
}

export default new ApiClient();
