import store from '@/store';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/StartUp.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/setpassword',
    name: 'setpassword',
    component: () => import( '@/views/SetPassword'),
  },

  {
    path: '/single',
    name: 'single',
    component: () => import( '@/views/SingleCheckView'),
  },
  {
    path: '/name-compare',
    name: 'name-compare',
    component: () => import( '@/views/NameCompareView'),
  },
  {
    path: '/batch',
    name: 'batch',
    component: () => import( '@/views/BatchCheckView'),
  },
  {
    path: '/querylog',
    name: 'querylog',
    component: () => import( '@/views/HistoryView'),
  },
  {
    path: '/thresholds',
    name: 'thresholds',
    component: () => import( '@/views/ThresholdsView'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import( '@/views/UsersView'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { isAuthenticated, passwordResetRequired } = store.getters;

  // Redirect for wrong pages, previous redirect with pathMatch(*) does not work
  if (router.options.routes.find(item => item.name == to.name) == undefined) {
    next('/');
  } else if (to.path == '/login' || to.path == '/setpassword' || isAuthenticated) {
    if (passwordResetRequired && to.path !== '/setpassword') {
      next('/setpassword');
    } else if (to.path == '/login' && isAuthenticated) {
      next('/');
    } else {
      next();
    }
  } else {
    next('/login');
  }
});

export default router;
